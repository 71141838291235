<template>
  <div class="ThawAndFree app-container">
    <div class="accountInfoPart">
      <p class="info-title-bar">当前账号信息</p>
      <div class="info-content">
        <template v-for="{ label, prop } in infoCol">
          <p v-if="prop === 'isProductPublic'" :key="prop">
            <span class="info-content-label">【{{ label }}】</span>
            <span class="info-content-font">{{ ISPRODUCTPUBLIC[userData[prop]] }}</span>
          </p>
          <p v-else-if="prop === 'levelName'" :key="prop">
            <span class="info-content-label">【{{ label }}】</span>
            <span class="info-content-font">{{
              userData.paymentAccountSimple && userData.paymentAccountSimple.memberLevel.levelName
            }}</span>
          </p>
          <p v-else-if="prop === 'salesAmount'" :key="prop">
            <span class="info-content-label">【{{ label }}】</span>
            <span class="info-content-font">{{
              userData.paymentAccountSimple && userData.paymentAccountSimple[prop]
            }}</span>
          </p>
          <p v-else-if="prop === 'accountAmount'" :key="prop">
            <span class="info-content-label">【{{ label }}】</span>
            <span class="info-content-font">{{
              userData.paymentAccountSimple && userData.paymentAccountSimple[prop]
            }}</span>
          </p>
          <p v-else-if="prop === 'isFreeze'" :key="prop">
            <span class="info-content-label">【{{ label }}】</span>
            <span class="info-content-font">{{ userData[prop] == 1 ? '已冻结' : '正常' }}</span>
          </p>
          <p v-else :key="prop">
            <span class="info-content-label">【{{ label }}】</span>
            <span class="info-content-font">{{ userData[prop] }}</span>
          </p>
        </template>
      </div>
      <div class="btnPart" v-loading="loading">
        <el-button size="small" @click="goBack">返回列表</el-button>
        <el-button
          size="small"
          v-if="userData.isFreeze === 1 && checkPermission('externaladmin:user:list:warningAndFreeze:thaw')"
          type="primary"
          @click="showThawDialog"
          >解除冻结</el-button
        >
        <el-button
          size="small"
          v-if="userData.isFreeze !== 1 && checkPermission('externaladmin:user:list:warningAndFreeze:warningBtn')"
          type="primary"
          @click="showWarningDialog"
          >警 告</el-button
        >
        <el-button
          size="small"
          v-if="userData.isFreeze !== 1 && checkPermission('externaladmin:user:list:warningAndFreeze:freeze')"
          type="danger"
          @click="showFrozenDialog"
          >冻结账号</el-button
        >
      </div>
    </div>
    <div class="processing-record">
      <p class="record-title-bar">
        <span>警告/冻结处理记录</span>
        <span class="record-info">
          <span class="record-info-warning">警告: {{ warningCount }}</span>
          <span class="record-info-frozen">冻结: {{ freezeCount }}</span>
        </span>
      </p>
      <CommonTable :cols="tableCols" :infoData="data" height="400" :selection="false">
        <template #contentSlot="{ scoped }"> {{ titleType(scoped.title) }} : {{ reasons(scoped) }} </template>
      </CommonTable>
      <PaginationBar
        ref="paginationBar"
        @refreshTableEventFun="refreshTableEventFun"
        :size="size"
        :page="page"
        :total="total"
      />
    </div>
    <WarningDialog
      :WarningDialogVisible.sync="WarningDialogVisible"
      :getUserData="getUserData"
      :init="init"
    ></WarningDialog>
    <FrozenDialog
      :FrozenDialogVisible.sync="FrozenDialogVisible"
      :getUserData="getUserData"
      :init="init"
    ></FrozenDialog>
    <ThawDialog :ThawDialogVisible.sync="ThawDialogVisible" :getUserData="getUserData" :init="init"></ThawDialog>
  </div>
</template>

<script>
import { read } from '@/api/adminUser'
import { infoCol, ISPRODUCTPUBLIC, tableCols, Reasons } from './option'
import WarningDialog from './warningDialog'
import FrozenDialog from './frozenDialog'
import ThawDialog from './thawDialog'
import { initDataMixin } from '@/mixins'
export default {
  mixins: [initDataMixin],
  components: { WarningDialog, FrozenDialog, ThawDialog },
  props: {},
  data() {
    return {
      userData: {},
      infoCol,
      ISPRODUCTPUBLIC,
      tableCols,
      WarningDialogVisible: false,
      FrozenDialogVisible: false,
      ThawDialogVisible: false,
      loading: false,
      url: '/externaladmin/authService/userFreezeRecord/freezeWarningList',
      xMenuType: 'other',
      query: {
        userId: this.$route.query.id
      },
      data: [],
      special: true,
      freezeCount: '',
      warningCount: '',
      Reasons
    }
  },
  computed: {
    titleType() {
      return (val) => {
        return ['发送警告', '冻结账号', '解除冻结'][val]
      }
    },
    reasons() {
      return ({ title, content, otherReason, unfreezeType, freezeDays }) => {
        if (title === 2) {
          if (unfreezeType) {
            return '冻结到期，系统解除冻结'
          } else {
            return `人工解除冻结；解除原因: ${content.join()}`
          }
        } else if (title === 1) {
          if (otherReason) {
            return `冻结天数${freezeDays}天；冻结原因：${content.join()} : ${otherReason}`
          } else {
            return `冻结天数${freezeDays}天；冻结原因：${content.join()}`
          }
        } else {
          if (otherReason) {
            return `${content.join()} : ${otherReason}`
          } else {
            return `${content.join()}`
          }
        }
      }
    }
  },
  created() {
    this.getUserData()
  },
  methods: {
    async getUserData() {
      try {
        this.loading = true
        const { detail } = await read(this.$route.query.id)
        this.userData = detail
        this.loading = false
      } catch {
        this.loading = false
      }
    },
    showWarningDialog() {
      this.WarningDialogVisible = !this.WarningDialogVisible
    },
    showFrozenDialog() {
      this.FrozenDialogVisible = !this.FrozenDialogVisible
    },
    showThawDialog() {
      this.ThawDialogVisible = !this.ThawDialogVisible
    },
    goBack() {
      this.$router.back()
    },
    initCallBack({ detail: { freezeCount, warningCount, list } }) {
      this.data = list
      this.freezeCount = freezeCount
      this.warningCount = warningCount
    }
  }
}
</script>

<style scoped lang="scss">
.ThawAndFree {
  .accountInfoPart {
    margin-bottom: 40px;
    .info-title-bar {
      margin: 0;
      margin-bottom: 20px;
      padding-left: 10px;
      border-left: 3px solid $color-primary;
    }
    .info-content {
      font-size: 14px;
      p {
        margin: 0;
        margin-bottom: 15px;
        .info-content-label {
          margin-right: 10px;
          font-weight: 600;
        }
        .info-content-font {
          color: #333;
        }
      }
    }
  }
  .processing-record {
    .record-title-bar {
      margin: 0;
      margin-bottom: 20px;
      padding-left: 10px;
      border-left: 3px solid $color-primary;
      .record-info {
        font-size: 14px;
        margin-left: 40px;
        .record-info-frozen {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
