<template>
  <div class="thawDialog">
    <el-dialog title="解除账号冻结" :visible.sync="dialogVisible" width="40%">
      <div class="account-info">
        <p class="info-item">确认解冻账号: {{ freezeUserInfo.accountName }}</p>
        <p class="info-item">冻结时间: {{ freezeTimeType }}</p>
        <p class="info-item">冻结原因: {{ Reasons }}</p>
      </div>
      <el-form :model="form" ref="ruleForm" :rules="rules" inline>
        <el-form-item label="解除原因" prop="otherCause">
          <el-input v-model="form.otherCause" type="textarea" rows="2" resize="none"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-popover placement="top" v-model="requireBtnVisible">
          <p>确认</p>
          <div>
            <el-button size="mini" @click="requireBtnVisible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="required" :loading="loading">确定</el-button>
          </div>
          <el-button slot="reference" size="mini" type="primary">确认解除</el-button>
        </el-popover>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { userFreeze, getFreezeInfo } from '@/api/user'
import { frozenTime } from '../frozenDialog/option'
export default {
  components: {},
  props: {
    ThawDialogVisible: {
      type: Boolean,
      default: false
    },
    init: {
      type: Function
    },
    getUserData: {
      type: Function
    }
  },
  data() {
    return {
      dialogVisible: false,
      requireBtnVisible: false,
      rules: {
        otherCause: [{ required: true, message: '请输入原因', trigger: 'blur' }]
      },
      form: {
        otherCause: ''
      },
      freezeUserInfo: {},
      frozenTime,
      loading: false
    }
  },
  computed: {
    freezeTimeType() {
      const res = this.frozenTime.find(({ value }) => value == this.freezeUserInfo.freezeTimeType)
      return res && res.label
    },
    Reasons() {
      let operationCause = this.freezeUserInfo.operationCause
      // let otherCause = this.freezeUserInfo.otherCause
      return operationCause?.join()
    }
  },
  watch: {
    ThawDialogVisible: {
      handler(newVal) {
        this.dialogVisible = newVal
      }
    },
    dialogVisible: {
      handler(newVal) {
        this.$emit('update:ThawDialogVisible', newVal)
        if (newVal) {
          this.getFreezeInfo()
        }
        if (!newVal) {
          this.$refs['ruleForm'].resetFields()
        }
      }
    }
  },
  methods: {
    required() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          this.loading = true
          const query = {
            otherCause: this.form.otherCause,
            operationCause: ['otherReason'],
            userId: this.$route.query.id,
            operationType: 2
          }
          const { code } = await userFreeze(query)
          if ($SUC({ code })) {
            this.$message.success('操作成功')
            this.getUserData()
            this.init()
          } else {
            this.$message.error('操作失败')
          }
          this.loading = false
          this.requireBtnVisible = false
          this.dialogVisible = false
        } else {
          this.requireBtnVisible = false
          return false
        }
      })
    },
    async getFreezeInfo() {
      const { detail } = await getFreezeInfo({ id: this.$route.query.id })
      this.freezeUserInfo = detail
    }
  }
}
</script>

<style scoped lang="scss">
.thawDialog {
  .account-info {
    margin-left: 10px;
    .info-item {
      margin: 0;
      margin-bottom: 15px;
    }
  }
}
</style>
