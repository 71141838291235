<template>
  <div class="frozenDialog">
    <el-dialog title="冻结账号" :visible.sync="dialogVisible" width="40%">
      <div class="head">
        <span>确认冻结账号: {{ freezeUserInfo.accountName }} </span>
        <span style="margin-left: 10px">(历史冻结次数: {{ freezeUserInfo.freezeCount }})</span>
      </div>
      <el-form :model="form" ref="ruleForm" :rules="rules" label-width="80px">
        <el-form-item prop="freezeTimeType" label="冻结时间">
          <el-select v-model="form.freezeTimeType" style="width: 180px" size="mini">
            <el-option v-for="{ label, value } in frozenTime" :key="value" :label="label" :value="value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="operationCause" label="冻结原因">
          <el-checkbox-group v-model="form.operationCause">
            <el-row v-for="{ label, value } in frozenReason" :key="value">
              <el-checkbox :label="value" name="frozenReason">
                {{ label }}
              </el-checkbox>
              <el-form-item
                v-if="value === 'otherReason' && form.operationCause.includes('otherReason')"
                prop="otherCause"
              >
                <el-input
                  v-model="form.otherCause"
                  type="textarea"
                  rows="2"
                  resize="none"
                  :maxlength="15"
                  show-word-limit
                  style="width: 300px"
                ></el-input>
              </el-form-item>
            </el-row>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <p class="frozenTip">注: 冻结后用户无法登录账号</p>
      <span slot="footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-popover placement="top" v-model="requireBtnVisible" trigger="manual">
          <p>确认</p>
          <div>
            <el-button size="mini" @click="requireBtnVisible = false">取消</el-button>
            <el-button type="danger" size="mini" @click="required" :loading="loading">确定</el-button>
          </div>
          <el-button slot="reference" size="mini" type="danger" @click="beforeRequest">确认冻结</el-button>
        </el-popover>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { frozenTime, frozenReason } from './option'
import { userFreeze, getFreezeInfo } from '@/api/user'
export default {
  components: {},
  props: {
    FrozenDialogVisible: {
      type: Boolean,
      default: false
    },
    init: {
      type: Function
    },
    getUserData: {
      type: Function
    }
  },
  data() {
    return {
      form: {
        freezeTimeType: '',
        operationCause: [],
        otherCause: ''
      },
      rules: {
        freezeTimeType: [{ required: true, message: '请选择冻结时间', trigger: 'change' }],
        operationCause: [{ type: 'array', required: true, message: '请至少选择一个原因', trigger: 'change' }],
        otherCause: [{ required: true, message: '请输入原因', trigger: 'blur' }]
      },
      dialogVisible: false,
      frozenTime,
      frozenReason,
      requireBtnVisible: false,
      loading: false,
      freezeUserInfo: {}
    }
  },
  computed: {},
  watch: {
    FrozenDialogVisible: {
      handler(newVal) {
        this.dialogVisible = newVal
      }
    },
    dialogVisible: {
      handler(newVal) {
        if (!newVal) {
          this.$refs['ruleForm'].resetFields()
        }
        if (newVal) {
          this.getFreezeInfo()
        }
        this.$emit('update:FrozenDialogVisible', newVal)
      }
    }
  },
  methods: {
    async required() {
      const { operationCause, freezeTimeType, otherCause } = this.form
      const query = {
        userId: this.$route.query.id,
        freezeTimeType,
        operationCause,
        otherCause,
        operationType: 1
      }
      try {
        this.loading = true
        const { code } = await userFreeze(query)
        if ($SUC({ code })) {
          this.$message.success('操作成功')
          this.getUserData()
          this.init()
        } else {
          this.$message.success('操作失败')
        }
      } catch {}

      this.loading = false
      this.requireBtnVisible = false
      this.dialogVisible = false
    },
    // 发送冻结请求
    sendRequest() {},
    beforeRequest() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.requireBtnVisible = true
        }
      })
    },
    async getFreezeInfo() {
      const { detail } = await getFreezeInfo({ id: this.$route.query.id })
      this.freezeUserInfo = detail
    }
  },
  created() {
    this.getFreezeInfo()
  }
}
</script>

<style scoped lang="scss">
.frozenDialog {
  .head {
    margin-bottom: 20px;
  }
  .frozenTip {
    text-align: right;
    margin: 0;
  }
}
</style>
